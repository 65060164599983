import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import Lawyer from '../images/lawyer.jpg';
import DemoForm from '../components/DemoForm';
import MujerStarter from '../images/mujer-starter.jpg';
import ClmHero from '../images/trato-clm-hero.png';

import Hero from '../images/hero.png';


function TratoVsMifiel({ pageContext: { locale }, location }) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>

      <section className="bg-grey">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="mb-3">Trato vs Mifiel - La mejor alternativa de Mifiel</h1>
              <h4>¿Por qué Trato es la mejor alternativa de Mifiel?</h4>
              <p className="text-muted mb-5">TRATO es una solución creada por abogados para ayudarte a manejar todo el proceso que implica un contrato y no solo con la firma electrónica. Además, nuestros abogados son expertos en tecnología legal, te podrán ayudar y asesorar para que puedas cumplir fácilmente con tus acuerdos y prevenir cualquier riesgo asociado.</p>
              <div className="card bg-light rounded shadow-sm mb-5">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <h4>Descubre todas las ventajas de Trato</h4>
                      <p className="text-muted mb-0">Agenda una demo con uno de nuestro asesores para que conozcas Trato y transformes la firma y gestión de tus documentos.</p>
                    </div>
                    <div className="col-lg-4">
                      <DemoForm type="tratoVsMifiel" />
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-muted mb-5">A continuación mencionamos las principales razones por las que las empresas prefieren a Trato como alternativa a Mifiel.</p>
              <span className="badge badge-primary py-1 px-2 lead mb-1">Razón #1 </span>
              <h5 className="font-weight-bold">Niveles de Blindaje para tus documentos.</h5>
              <p className="text-muted mb-5">Obtén hasta 4 niveles distintos de blindaje en la firma electrónica de tus documentos, desde trazo biometrico, geolocalización, hasta firmar por videollamada.</p>

              <span className="badge badge-primary py-1 px-2 lead mb-1">Razón #2</span>
              <h5 className="font-weight-bold">Atención al cliente.</h5>
              <p className="text-muted mb-5">Nuestro equipo de atención al cliente tiene el objetivo de proporcionarle los procesos y herramientas necesarias para que su empresa desarrolle ventajas competitivas sobre la competencia. Estamos disponibles para su empresa, puede contactarnos mediante nuestro chat en línea, enviarnos un email o llamándonos. </p>

              <span className="badge badge-primary py-1 px-2 lead mb-1">Razón #3</span>
              <h5 className="font-weight-bold">Nuevas características cada semana.</h5>
              <p className="text-muted">Trato se actualiza constantemente con la finalidad de ayudar a las empresas a cumplir con sus acuerdos con nuevas y mejores tecnologías.</p>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default TratoVsMifiel;
